<template>
  <el-card class="m-4 container-credit">
    <h1 class="uppercase text-center font-bold title">H-CREDIT</h1>
    <el-input
      class="input-search mt-4"
      placeholder="Nhập tên bệnh nhân, SĐT, số BHYT"
      v-model="keyword"
      clearable
      @input="handleSearch"
    >
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <div class="mt-4" v-if="data.length">
      <div class="flex justify-end">
        <el-button type="primary" plain @click="handleExportReport">Xuất báo cáo giao dịch</el-button>
      </div>
      <div class="mt-2">
        <el-table :data="data" style="width: 100%" v-loading="isLoading">
          <el-table-column prop="name" label="TÊN KHÁCH HÀNG"></el-table-column>
          <el-table-column prop="phone" label="ĐIỆN THOẠI"></el-table-column>
          <el-table-column prop="insurance_number" label="BHYT"></el-table-column>
          <el-table-column prop="birthday" label="NGÀY SINH">
            <template slot-scope="scope">
              <span>{{ appUtils.formatDate(scope.row.birthday) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="points" label="SỐ DƯ ĐIỂM"></el-table-column>
          <el-table-column label="THAO TÁC" width="150">
            <template slot-scope="scope">
              <div
                @click="handleAction(scope.row)"
                class="action shadow-sm cursor-pointer"
                :style="`background: ${getStatusColor(scope.row)}; color: ${getTextColor(scope.row)}`"
              >{{ getStatus(scope.row) }}</div>
            </template>
          </el-table-column>
          <el-table-column label width="60" align="right">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                content="Hoàn điểm"
                v-if="scope.row && scope.row.user_id"
                placement="bottom-end"
              >
                <template #content>
                  <div class="cursor-pointer" @click="handleReturnPoints(scope.row)">Hoàn điểm</div>
                </template>
                <img class="cursor-pointer" src="/images/icon-more.svg" alt />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex items-center justify-end mt-3">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.page_num"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pagination.page_size"
            layout="sizes, prev, pager, next"
            :total="pagination.total"
            background
            v-if="data.length"
          ></el-pagination>
        </div>
      </div>
    </div>
    <modal-active-account ref="ModalActiveAccount" @activeSuccess="handleActiveSuccess"></modal-active-account>
    <modal-use-points ref="ModalUsePoints"></modal-use-points>
    <modal-return-points ref="ModalReturnPoints"></modal-return-points>
    <modal-export-report ref="ModalExportReport"></modal-export-report>
  </el-card>
</template>
<script>
import { mapActions } from 'vuex'
import ModalActiveAccount from '../../components/HCredit/ModalActiveAccount.vue'
import ModalUsePoints from '../../components/HCredit/ModalUsePoints.vue'
import ModalReturnPoints from '../../components/HCredit/ModalReturnPoints.vue'
import ModalExportReport from '../../components/HCredit/ModalExportReport.vue'
import { debounce } from 'lodash'
import { mixinQueryParams } from '@/utils/mixinQueryParams.js'
import appUtils from '@/utils/appUtils'
export default {
  name: 'ListCredit',
  components: { ModalActiveAccount, ModalUsePoints, ModalReturnPoints, ModalExportReport },
  mixins: [mixinQueryParams],
  data () {
    return {
      keyword: '',
      data: [],
      isLoading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      appUtils
    }
  },
  computed: {
    ...mapActions('appointmentStore', ['getSearchAllRequest'])
  },
  watch: {
    //
  },
  mounted () {
    if (this.$route.query?.keyword) {
      this.keyword = this.$route.query?.keyword
      this.getListPatient()
    }
  },
  methods: {
    getStatus (item) {
      if (!item?.user_id) return 'Kích hoạt'
      else return 'Sử dụng điểm'
    },
    getStatusColor (item) {
      if (!item?.user_id) return '#FEF0C7'
      else return '#E9F0FF'
    },
    getTextColor (item) {
      if (!item?.user_id) return '#B54708'
      else return '#20409B'
    },
    handleReturnPoints (item) {
      this.$refs.ModalReturnPoints.openModal(true, item)
    },
    handleAction (item) {
      if (!item.user_id) {
        this.handleActiveAcount(item)
      }
      if (item.user_id) {
        this.handleUsePoints(item)
      }
    },
    handleActiveAcount (item) {
      this.$refs.ModalActiveAccount.openModal(true, item)
    },
    handleExportReport (item) {
      this.$refs.ModalExportReport.openModal(true, item)
    },
    handleUsePoints (item) {
      this.$refs.ModalUsePoints.openModal(true, item)
    },
    async getListPatient () {
      try {
        this.isLoading = true
        const paramsData = {
          keyword: this.$route.query?.keyword || this.keyword,
          page_size: +this.$route.query?.page_size || this.pagination.page_size,
          sort_by: 'created_at',
          order: 'desc',
          page_num: +this.$route.query?.page_num || this.pagination.page_num,
          ws_id: this.$clinicId
        }
        if (this.keyword) {
          const res = await this.$rf.getRequest('DoctorRequest').getPersonList(paramsData)
          this.data = res?.data?.data
          this.pagination = res?.data?.page
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSearch: debounce(function () {
      if (this.keyword) {
        this.pagination.page_num = 1
        this.handleSetFilter({ keyword: this.keyword, page_num: this.pagination.page_num })
        this.getListPatient()
      } else {
        this.handleClearQuery()
        this.data = []
      }
    }, 500),
    handleActiveSuccess () {
      this.$toast.open({
        message: 'Kích hoạt tài khoản thành công',
        type: 'success'
      })
      this.getListPatient()
    },
    handleSizeChange (size) {
      this.pagination.page_size = size
      this.pagination.page_num = 1
      this.handleSetFilter({ ...this.pagination })
      this.getListPatient()
    },
    handleCurrentChange (page) {
      this.pagination.page_num = page
      this.handleSetFilter({ ...this.pagination })
      this.getListPatient()
    }
  }
}
</script>
<style lang="scss" scoped>
.container-credit {
  min-height: 200px;
  .title {
    color: #20419b;
  }
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
  }
}
::v-deep.el-input .el-input__inner {
  height: 50px;
  font-size: 16px !important;
}
</style>